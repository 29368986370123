import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Promotion } from 'src/app/shared/models/promotion';
import { NumberBriefsPipe } from 'src/app/shared/pipes/html-content/number-briefs.pipe';
import { SnackbarService } from 'src/app/shared/Services/snackbar.service';
import { Comment, FollowingInfo, Post, ProductSearch, UserFollowItem } from 'src/app/utils/post.model';
import { OrderStatus, ProductOrder } from 'src/app/utils/product-order.model';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.POST_API_URL;

@Injectable({
   providedIn: 'root'
})
export class PostService {
   private CBGainedSource: BehaviorSubject<any> = new BehaviorSubject(null);
   CBGainedCounts = this.CBGainedSource.asObservable();

   private snackbar = inject(SnackbarService);

   constructor(
      private http: HttpClient,
      private numberBriefs: NumberBriefsPipe
   ) {}

   getAllpostsByPage(page: number, tag?: string, category?: string, items: number = 10): Observable<Post[]> {
      if (tag)
         return this.http.get<Post[]>(
            `${BASE_URL}hashtags/postsbyhashtag?keyword=${tag}&page=${page}&limit=${items}&sort=createdAt,desc`
         );
      return this.http.get<Post[]>(
         `${BASE_URL}posts?page=${page}&limit=${items}&sort=createdAt,desc&category=${category}`
      );
   }

   getUserpostsByPage(
      page: number,
      items: number = 10,
      userId: number | null,
      type: 'POST' | 'BOOKMARK' | 'STORY' = 'POST',
      category: string
   ): Observable<Post[]> {
      if (type === 'BOOKMARK') {
         return this.http.get<Post[]>(
            `${BASE_URL}bookmarks/postbybookmark?page=${page}&limit=${items}&sort=createdAt,desc`
         );
      }
      if (type === 'STORY') {
         return this.getStoriesByUser(page, items, userId!);
      }

      return this.http.get<Post[]>(
         `${BASE_URL}posts/myposts?page=${page}&limit=${items}&userid=${userId}&sort=createdAt,desc&category=${category}`
      );
   }

   uploadImage(data: FormData | null) {
      return this.http.post<any>(`${BASE_URL}images`, data);
   }

   uploadImageCompression(data: FormData | null) {
      return this.http.post<any>(`${BASE_URL}images/imagescompression`, data);
   }

   uploadFile(data: FormData | null) {
      return this.http.post<any>(`${BASE_URL}files`, data);
   }

   uploadVideo(data: FormData | null) {
      return this.http.post<any>(`${BASE_URL}videos/videoupload`, data);
   }

   newPost(data: any): Observable<Post> {
      return this.http.post<Post>(`${BASE_URL}posts`, data);
   }

   updatePost(data: any, postId: string): Observable<Post> {
      return this.http.put<Post>(`${BASE_URL}posts/${postId}`, data);
   }

   getPostByDetails(postId: string): Observable<Post> {
      return this.http.get<Post>(`${BASE_URL}posts/${postId}`);
   }

   deletePostById(postId: string) {
      return this.http.delete(`${BASE_URL}posts/${postId}`);
   }

   addOrDeleteBookmark(postId: string, status: boolean) {
      return this.http.post(`${BASE_URL}bookmarks`, {
         post: postId,
         status: status
      });
   }

   getAllBookmarks(): Observable<Post[]> {
      return this.http.get<Post[]>(`${BASE_URL}bookmarks/postbybookmark`);
   }

   //comment
   newComment(data: Comment, edit: boolean): Observable<{ comment: Comment }> {
      if (edit) return this.http.put<{ comment: Comment }>(`${BASE_URL}comments/${data._id}`, data);
      return this.http.post<{ comment: Comment }>(`${BASE_URL}comments`, data);
   }

   getCommentsByPostId(postId: string, page: number, items: number = 10): Observable<Comment[]> {
      return this.http.get<Comment[]>(`${BASE_URL}comments/${postId}?page=${page}&limit=${items}&sort=createdAt,desc`);
   }

   deleteComment(commentId: string) {
      return this.http.delete(`${BASE_URL}comments/${commentId}`);
   }
   //like
   changeLike(data: { post: string; status: boolean }) {
      return this.http.post(`${BASE_URL}addlike`, data);
   }

   addView(storyId: string) {
      return this.http.get(`${BASE_URL}posts/readstory/${storyId}`);
   }

   //follow
   getUserFollowInfo(userId: any): Observable<FollowingInfo> {
      return this.http.get<any>(`${BASE_URL}getfollowinfo/${userId}`);
   }

   getUserFollow(userId: any, page: number, type: string, items: number = 20): Observable<UserFollowItem[]> {
      if (type === 'followers')
         return this.http.get<any[]>(`${BASE_URL}getuserfollowers/${userId}?page=${page}&perPage=${items}`);
      return this.http.get<any[]>(`${BASE_URL}userfollowedby/${userId}?page=${page}&perPage=${items}`);
   }

   getSixOfUsersToFollow(): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}followers/usersrandomnotfollowed`);
   }

   getSuggestedUserToFollow(page: number, items: number = 10): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}followers/usersnotfollowed?page=${page}&limit=${items}`);
   }

   changeFollow(data: { user_id: number; status: boolean }) {
      return this.http.post<any[]>(`${BASE_URL}addfollow`, data);
   }

   //hashtag
   createNewHashtag(data: { user: number | undefined; text: string; post: string }): Observable<any> {
      return this.http.post<any>(`${BASE_URL}hashtags`, data);
   }

   searchForHashtags(keyword: string): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}hashtags?search=${keyword}`);
   }

   hashtagTrendingList(): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}hashtags/tophashtags`);
   }

   //mention
   onSendMention(data: { username: string; postId: string }): Observable<any> {
      return this.http.post<any>(`${BASE_URL}mentions`, data);
   }

   //promotion
   getCBFarm(page: number, items: number = 10): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}promotions?page=${page}&limit=${items}&sort=createdAt,desc`);
   }

   getPrevAndNextPromotion(promotionId: string): Observable<{ previous: Promotion; next: Promotion }> {
      return this.http.get<{ previous: Promotion; next: Promotion }>(
         `${BASE_URL}promotions/prevandnext?id=${promotionId}`
      );
   }

   gainPromotionCB(promotionId: string, referralCode: string): Observable<any[]> {
      return this.http.get<any[]>(`${BASE_URL}promotions/cbgain/${promotionId}?referralcode=${referralCode}`);
   }

   setCBGained(cbCount: string) {
      const cb = this.numberBriefs.transform(Number(cbCount));
      this.snackbar.openSnackBar({
         mode: 'light',
         status: 'success',
         message: `${cb} CB Gained! try it again in 6 hours`
      });
      const audio = new Audio(environment.DOMAIN + '/assets/files/cb-gain.wav');
      audio.load();
      audio.play();
   }

   getAllStories(): Observable<Post[]> {
      return this.http.get<Post[]>(`${BASE_URL}posts/stories`);
   }

   getStoriesByUser(page: number, items: number, userId: number): Observable<Post[]> {
      return this.http.get<Post[]>(
         `${BASE_URL}posts/mystories?userid=${userId}&page=${page}&limit=${items}&sort=createdAt,desc`
      );
   }

   // Produccts

   getAllProductsByPage(page: number, items: number, userId?: number, search?: ProductSearch): Observable<Post[]> {
      if (search?.keyword || search?.discount) {
         return this.http.get<Post[]>(
            `${BASE_URL}posts/productsbysearch?keyword=${search.keyword ?? ''}&discount=${
               search.discount ?? false
            }&page=${page}&limit=${items}&sort=createdAt,desc`
         );
      }
      if (userId) {
         return this.http.get<Post[]>(
            `${BASE_URL}posts/myproducts?userid=${userId}&page=${page}&limit=${items}&sort=createdAt,desc`
         );
      }
      return this.http.get<Post[]>(`${BASE_URL}posts/products?page=${page}&limit=${items}&sort=createdAt,desc`);
   }

   orderProduct(data: { postId: string; count: number; remarks: string }): Observable<any> {
      return this.http.post<any>(`${BASE_URL}productorders`, data);
   }

   updateOrderStatus(status: OrderStatus, orderId: string, sellerDescription?: string): Observable<any> {
      return this.http.put<any>(`${BASE_URL}productorders/${orderId}`, { status, sellerDescription });
   }

   getOrderList(page: number, items: number, ownerId?: number): Observable<ProductOrder[]> {
      if (ownerId) {
         return this.http.get<ProductOrder[]>(
            `${BASE_URL}/productorders/byownerid?ownerid=${ownerId}&page=${page}&limit=${items}&sort=createdAt,desc`
         );
      }
      return this.http.get<ProductOrder[]>(
         `${BASE_URL}/productorders/bybuyerid?page=${page}&limit=${items}&sort=createdAt,desc`
      );
   }
}
